import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Herzlich Willkommen bei eBay Editor!</h1>
        <p>Die Anwendung funktioniert einwandfrei!</p>
      </header>
    </div>
  );
}

export default App;
